import React from 'react';

function FieldReadonly(props) {
  const { name, label, value,  lightblue, blue, formula } = props
  const classLightBlue = lightblue ? "franja-lightblue" : ""
  const classBlue = blue ? 'franja-blue' : ''
  const classes = "row " + classLightBlue + " " + classBlue
  return (
    <div className={classes} >
      <label
        for={name}
        className="label-field small">
        {label}:
      </label>
      <input
        className="input-new readonly"
        type="text"
        id={name}
        value={value}
        readOnly />
        {formula}
    </div>

  );
}

export default FieldReadonly
