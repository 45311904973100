import React from 'react';
import Logo from '../images/Header/logorentabilidad.svg';

export default class Header extends React.Component {


  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <header className='w-100'>
        <ul className="nav d-flex justify-content-center w-100">
          <li className="nav-item">
            <img src={Logo} width="400" alt="Logo" className="imgheader" />
          </li>
        </ul>
      </header>
    );
  }
}