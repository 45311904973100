import React from 'react';
import franja from '../images/Tabla/franjatabla.svg';

function Tabletitle() {
  return (
    <div className="input-group mb-1">
    <img src={franja} alt="Título" className="franja-titulo" />
  </div>
  );
}

export default Tabletitle
