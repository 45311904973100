import React from 'react';

function Field(props) {
  const { name, label, value, lightblue, blue } = props
  const classLightBlue = lightblue ? "franja-lightblue" : ""
  const classBlue = blue ? 'franja-blue' : ''
  const classes = "row " + classLightBlue + " " + classBlue;

  return (
    <div className={classes} >
      <label
        for={name}
        className="label-field small">
        {label}:
      </label>
      <input
        className="input-new"
        type="text"
        id={name}
        value={value}
        onChange={(evt)=>props.setValue({name,evt})}
         />
    </div>

  );
}

export default Field
