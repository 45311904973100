import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer'
import Field from '../Components/Field'
import FieldReadonly from '../Components/FieldReadonly'
import Tabletitle from '../Components/Tabletitle'


class Tabla extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      costoduraphat: 8,
      cantidadaplicaciones: 90,
      costomateriales: 1,
      valorxaplicacion: 4
    };
    this.setValue = this.setValue.bind(this);
  }

  setValue(objInput) {
    const { name, evt } = objInput
    const numberDe = this.decurrencyFormat(evt.target.value)
    let obj = `[{"${name}":"${numberDe}"}]`;
    console.log("obj::", obj);
    this.setState(JSON.parse(obj)[0]);
  }

  formatNumber = (num) => {
    if (isNaN(num) || num<0) {
      return 0
    }
    return num.toFixed(2)
  }

  currencyFormat = (num) => {
    if (typeof num === "string") {
      if (isNaN(num)) {
        num = 0
      }
      num = this.decurrencyFormat(num)
    }else if(typeof num === "number"){
      if(isNaN(num) ||  num < 0){
        num = 0
      }
    }
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  validNumber(item, input) {
    const expre = /^([0-9])*$/;
    if (!expre.test(input.target.value)) {
      input.target.classList.add('is-invalid');
      document.querySelector('.invalid-feedback').style.opacity = 1;
      document.querySelector('.invalid-feedback').style.display = 'block';
    } else {
      input.target.classList.remove('is-invalid');
      document.querySelector('.invalid-feedback').style.opacity = 0;
      document.querySelector('.invalid-feedback').style.display = 'none';
      let obj = `[{"${item}":"${input.target.value}"}]`;
      this.setState(JSON.parse(obj)[0]);
    }
  }

  decurrencyFormat = (str) => {
    console.log("str::", str)
    str = str.replace(/\./g, '')
    str = str.replace(/\$/g, '')
    return Number(str)
  }

  render() {
    const { costoduraphat, cantidadaplicaciones, costomateriales, valorxaplicacion } = this.state
    const costoxpaciente = Number(costoduraphat) / Number(cantidadaplicaciones);
    const costostotalesxpaciente = Number(costomateriales) + Number(costoxpaciente);
    const gananciaxpaciente = Number(valorxaplicacion) - Number(costostotalesxpaciente);
    const gananciatotal = Number(gananciaxpaciente) * Number(cantidadaplicaciones)
    return (
      <div className="container">
        <Header />
        <div className="title-container">
          <p className="text-muted text-center mb-2 small">
            <strong>Ingrese los datos en la tabla de simulación</strong> de acuerdo a los valores que usted considere y verifique la ganancia que puede obtener por cada tubo de<br /><strong className="text-danger">Barniz de Flúor Colgate Duraphat.</strong>
          </p>
        </div>
        <div className="tabla-container">
          <form className="tabla">
            <Tabletitle />
            <div className="title-container">
              <p className="text-muted text-center mb-2 small intro">
                <strong>A continuación le presentamos un ejemplo. Para el ejercicio debe introducir los valores que considere para su caso personal.</strong>
              </p>
            </div>

            <Field
              label='Costo Colgate Duraphat (tubo 10mL)'
              name='costoduraphat'
              value={costoduraphat}
              setValue={this.setValue}
              lightblue={false}
              blue={false} />
            <Field
              label='# Aplicaciones promedio x tubo'
              name='cantidadaplicaciones'
              value={cantidadaplicaciones}
              setValue={this.setValue}
              lightblue={false}
              blue={false}
            />
            <FieldReadonly
              label='Costo por paciente (boca completa)'
              name='costoxpaciente'
              value={this.formatNumber(costoxpaciente)}
              formula='*'
              lightblue={false}
              blue={false}
            />
            <Field
              label='Costos de materiales para la atención del paciente'
              name='costomateriales'
              value={costomateriales}
              setValue={this.setValue}
              lightblue={false}
              blue={false} />
            <FieldReadonly
              label='Costos totales por paciente (boca completa)'
              name='costostotalesxpaciente'
              value={this.formatNumber(costostotalesxpaciente)}
              formula='*'
              lightblue={false}
              blue={false} />
            <Field
              label='Valor x aplicación para el paciente'
              name='valorxaplicacion'
              value={valorxaplicacion}
              setValue={this.setValue}
              lightblue={true}
              blue={false} />
            <FieldReadonly
              label='Ganancia x paciente (boca completa):'
              name='gananciaxpaciente'
              value={this.currencyFormat(gananciaxpaciente)}
              formula='*'
              lightblue={true}
              blue={false} />
            <FieldReadonly
              label='Ganancia x tubo:'
              name='gananciatotal'
              value={this.currencyFormat(gananciatotal)}
              formula='*'
              lightblue={false}
              blue={true}
            />
          </form>
          <div className="title-container">
            <p className="text-muted text-center mb-2 small">
              *Estas casillas están predeterminadas con fórmulas para efecto del ejercicio
          </p>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Tabla